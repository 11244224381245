.productsContainer {
  padding: 0 var(--cui-spacings-kilo);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: var(--cui-spacings-byte);
  row-gap: var(--cui-spacings-giga);
}

.popularTopicsHeadline {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}

.popularTopicsContainer {
  display: flex;
  padding-top: 76px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: var(--cui-spacings-giga);
}

.topicSections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 var(--cui-spacings-kilo);
  gap: var(--cui-spacings-byte);
}

.showMoreButton {
  display: flex;
  align-self: center;
  color: black;
  margin-top: var(--cui-spacings-mega);
}

.otherGuidesContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--cui-spacings-mega);
  row-gap: var(--cui-spacings-peta);
  align-self: stretch;
  padding: 0 var(--cui-spacings-kilo);
}

.otherGuidesHeadline {
  align-self: stretch;
  padding: 0 var(--cui-spacings-kilo);
}

.otherGuidesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: var(--cui-spacings-mega);
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .productsContainer {
    padding: 0 var(--cui-spacings-kilo);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--cui-spacings-byte);
    row-gap: var(--cui-spacings-giga);
  }

  .popularTopicsHeadline {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  .popularTopicsContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    padding-top: 100px;
    align-content: stretch;
  }

  .otherGuidesContainer {
    display: flex;
    max-width: 1280px;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--cui-spacings-mega);
    row-gap: var(--cui-spacings-peta);
    align-self: stretch;
    flex: 0 0 calc(50% - 10px);
  }

  .otherGuidesHeadline {
    align-self: stretch;
    padding: 0 var(--cui-spacings-kilo);
  }

  .otherGuidesWrapper {
    display: flex;
    padding-top: 76px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: var(--cui-spacings-mega);
  }

  .topicSections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 var(--cui-spacings-kilo);
    gap: var(--cui-spacings-byte);
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .productsContainer {

    padding: 0 var(--cui-spacings-kilo);


    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--cui-spacings-byte);
    row-gap: var(--cui-spacings-giga);
  }

  .popularTopicsHeadline {
    color: var(--cui-fg-normal);
    text-align: center;
  }

  .popularTopicsContainer {
    display: flex;
    padding-top: 100px;
    width: auto;
    align-items: center;
    align-self: center;
    margin: auto;
  }

  .otherGuidesContainer {
    display: flex;
    max-width: 1280px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--cui-spacings-giga);
    row-gap: var(--cui-spacings-zetta);
    align-self: stretch;
  }

  .otherGuidesHeadline {
    align-self: stretch;
    padding: 0 32px;
  }

  .otherGuidesWrapper {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    margin: auto;
    width: 100%;
    gap: var(--cui-spacings-giga)
  }

  .topicSections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 var(--cui-spacings-kilo);
    gap: var(--cui-spacings-byte);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1768px) {
  .productsContainer {

    padding: 0 var(--cui-spacings-kilo);


    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--cui-spacings-byte);
    row-gap: var(--cui-spacings-giga);
  }

  .otherGuidesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--cui-spacings-giga);
    row-gap: var(--cui-spacings-zetta);
    align-self: stretch;
    margin: auto;
    width: 100%;
  }

  .otherGuidesHeadline {
    align-self: stretch;
    padding: 0 32px;
  }

  .popularTopicsContainer {
    display: flex;
    padding-top: 100px;
    width: auto;
    align-items: center;
    align-self: center;
    margin: auto;
  }

  .topicSections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 var(--cui-spacings-kilo);
    gap: var(--cui-spacings-byte);
  }

  .otherGuidesWrapper {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 1280px;
    gap: var(--cui-spacings-giga)
  }
}

@media screen and (min-width: 1768px) {
  .productsContainer {
    padding: 0 var(--cui-spacings-kilo);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--cui-spacings-byte);
    row-gap: var(--cui-spacings-giga);
  }

  .otherGuidesContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--cui-spacings-giga);
    row-gap: var(--cui-spacings-zetta);
    align-self: stretch;
    margin: auto;
    width: 100%;
  }

  .otherGuidesHeadline {
    align-self: stretch;
    padding: 0 32px;
  }

  .popularTopicsContainer {
    display: flex;
    padding-top: 100px;
    width: auto;
    align-items: center;
    align-self: center;
    margin: auto;
  }

  .topicSections {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 var(--cui-spacings-kilo);
    gap: var(--cui-spacings-byte);
  }

  .otherGuidesWrapper {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: 1280px;
    gap: var(--cui-spacings-giga)
  }
}