.image {
  object-fit: cover;
  aspect-ratio: 4/5;
  height: auto;
  width: 100%;
}

.card {
  height: auto;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 0;
  cursor: pointer;
}

.headline {
  margin-right: auto;
  margin-left: 16px;
}

.product {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  gap: 8px;
  flex: 0 0 calc(50% - 8px);
  grid-column: auto / span 6;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .card {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .headline {
    margin-right: auto;
  }

  .product {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    gap: 8px;
    flex: 0 0 calc(50% - 8px);
    grid-column: auto / span 6;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    gap: 16px;
  }

}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 0;
  }

  .headline {
    margin-right: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    gap: 24px;
    width: 25%;
    flex: 0 0 calc(50% - 8px);
  }

  .product {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    grid-column: auto / span 6;
    flex: 0 0 calc(50% - 8px);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1768px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 0;
  }

  .headline {
    margin-right: auto;
  }

  .product {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    grid-column: auto / span 3;
    flex: 0 0 calc(25% - 8px);
  }
}

@media screen and (min-width: 1768px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 4/5;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 0;
  }

  .headline {
    margin-right: auto;
  }

  .product {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    grid-column: auto / span 3;
    flex: 0 0 calc(25% - 8px);
  }
}