.shimmer[aria-busy="true"] {
    pointer-events: none;
    user-select: none;
  }
  
  @keyframes pulse {
    0% {
      background-position: calc(-1 * var(--shimmer-pulse-width)) 0;
    }
  
    50% {
      background-position: calc(var(--shimmer-pulse-width) + 100%) 0;
    }
  
    100% {
      background-position: calc(var(--shimmer-pulse-width) + 100%) 0;
    }
  }
  
  .base {
    display: inline-block;
    line-height: 0;
  }
  
  .shimmer {
    --shimmer-pulse-width: 56rem;
  
    background-color: var(--cui-bg-highlight);
    background-image: linear-gradient(
      -45deg,
      var(--cui-bg-highlight) 30%,
      var(--cui-bg-subtle) 50%,
      var(--cui-bg-highlight) 70%
    );
    background-repeat: no-repeat;
    background-size: var(--shimmer-pulse-width) 100%;
    border-radius: var(--cui-border-radius-byte);
    animation: pulse 3s ease-in-out infinite;
    opacity: 0.4;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .shimmer {
      animation: none;
    }
  }
  
  .shimmer > * {
    visibility: hidden;
  }
  
  .circle {
    border-radius: var(--cui-border-radius-circle);
  }