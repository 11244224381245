.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.header {
  display: flex;
  padding: 24px 24px 0 24px;
  justify-content: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .header {
    display: flex;
    padding: 24px 24px 0 24px;
    justify-content: center;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    padding: 32px 32px 0 32px;
    justify-content: flex-start;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .header {
    display: flex;
    padding: 32px 32px 0 32px;
    justify-content: flex-start;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (min-width: 1760px) {
  .container {
    max-width: 1760px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .header {
    display: flex;
    padding: 32px 32px 0 32px;
    justify-content: flex-start;
  }
}