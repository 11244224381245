.tabs {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
}

.tabs::after {
  display: none;
}

.tab {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  gap: 4px;
  background: none;
}

.tab[aria-selected="true"] {
  background: none;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 3px solid var(--cui-fg-normal);
}

.tab::after {
  display: none;
}

.icon {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .tabs {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
  }

  .tabs::after {
    display: none;
  }

  .tab {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    gap: 4px;
    background: none;
  }

  .tab::after {
    display: none;
  }

  .tab[aria-selected="true"] {
    background: none;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 3px solid var(--cui-fg-normal);
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .tabs {
    display: flex;
    justify-content: flex-start;
  }

  .tab {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .tabs {
    display: flex;
  }

  .tab {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .icon {
    width: 24px;
    height: 24px;
  }
}