.popularTopic {
  display: flex;
  flex-direction: row;
  flex: 1 0 calc(50% - 10px);
}

.popularTopic:last-child {
  flex-grow: 0;
}

.card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--cui-border-subtle);
  background: var(--cui-bg-normal);
  gap: 8px;
  height: auto;
  width: 100%;
  cursor: pointer;
}

.card:hover {
  background: var(--cui-bg-subtle);
  border: 1px solid var(--cui-border-strong);
}

.cardHeader {
  flex-grow: 1;
}

.cardTitle {
  padding-top: 24px;
  font-size: 14px;
}

.cardImage {
  width: 24px;
  height: 24px;
  aspect-ratio: 4/5;
  object-fit: cover;
}

.cardDescription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  padding-top: 4px;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .card {
    display: flex;
    height: auto;
    width: 224px;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .cardImage {
    width: 24px;
    height: 24px;
    object-fit: cover;
    aspect-ratio: 4/5;
  }

  .cardTitle {
    font-size: 14px
  }

  .cardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    padding-top: 4px;
  }

  .popularTopic {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 0 calc(50% - 10px);
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .popularTopic {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 0 calc(33% - 10px);
  }

  .card {
    display: flex;
    height: auto;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .cardDescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 16px;
    text-overflow: unset;
    padding-top: 4px;
  }

  .cardImage {
    width: 32px;
    height: 32px;
    object-fit: cover;
    aspect-ratio: 4/5;
  }

  .cardTitle {
    font-size: 16px
  }
}

@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .popularTopic {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    flex: 0 0 calc(33.333333% - 6px);
  }

  .card {
    display: flex;
    height: auto;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .cardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 16px;
    text-overflow: unset;
    padding-top: 4px;
  }

  .cardImage {
    height: 32px;
    object-fit: cover;
    aspect-ratio: 4/5;
    width: 32px;
  }

  .cardTitle {
    font-size: 16px
  }
}

@media screen and (min-width: 1760px) {
  .popularTopic {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    flex: 0 0 calc(33.333333% - 8px);
  }

  .cardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 16px;
    text-overflow: unset;
    padding-top: 4px;
  }

  .cardImage {
    height: 32px;
    object-fit: cover;
    aspect-ratio: 4/5;
    width: 32px;
  }

  .cardTitle {
    font-size: 16px;
  }
}