.image {
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: 24px;
  aspect-ratio: 5/4;
}

.card {
  height: auto;
  width: 100%;
  overflow: hidden;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.headline {
  margin-right: auto;
}

.container {
  display: flex;
  flex-direction: column;
  width: 25%;
  border-radius: 24px;
  flex: 1 0 calc(50% - 16px);
}

.subHeadline {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  margin-bottom: 16px;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  margin-top: 8px;
  gap: 8px;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 5/4;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 0;
    border: 0;
  }

  .headline {
    margin-right: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 25%;
    flex: 1 0 calc(50% - 16px);
  }

  .subHeadline {
    font-size: 14px;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 5/4;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 0;
    border: 0;
  }

  .card:hover {
    background: var(--cui-bg-subtle);
  }

  .headline {
    margin-right: auto;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 25%;
    flex: 1 0 calc(33.333333% - 16px);
  }

  .subHeadline {
    font-size: 14px;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .card {
    width: 100%;
    height: auto;
    padding: 0;
    border: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 25%;
    flex: 0 0 calc(25% - 24px);
  }

  .card:hover {
    background: var(--cui-bg-subtle);
  }

  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 5/4;
  }

  .headline {
    margin-right: auto;
  }

  .subHeadline {
    font-size: 16px;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
}

@media screen and (min-width: 1760px) {
  .card {
    width: 100%;
    height: auto;
    padding: 0;
    border: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 360px;
    flex: 0 0 calc(25% - 24px);
  }

  .card:hover {
    background: var(--cui-bg-subtle);
  }

  .image {
    object-fit: cover;
    height: auto;
    width: 100%;
    aspect-ratio: 5/4;
  }

  .headline {
    margin-right: auto;
  }

  .subHeadline {
    font-size: 16px;
  }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 12px;
  }
}
