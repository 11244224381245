/* Mobile styles */
.container {
  display: flex;
  padding-top: var(--cui-spacings-kilo);
  flex-direction: column-reverse;
  justify-content: space-between;
  align-self: stretch;
}

.headline {
  display: flex;
  padding: var(--cui-spacings-giga) var(--cui-spacings-kilo) 0 var(--cui-spacings-kilo);
  align-items: flex-start;
  align-self: stretch;
}

/* Mobile styles */
@media screen and (max-width: 480px) {
  .container {
    display: flex;
    padding-top: var(--cui-spacings-kilo);
    flex-direction: column-reverse;
    justify-content: space-between;
    align-self: stretch;
  }
  
  .headline {
    display: flex;
    padding: var(--cui-spacings-giga) var(--cui-icon-sizes-kilo) 0 var(--cui-icon-sizes-kilo);
    align-items: flex-start;
    align-self: stretch;
  }
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
    padding: var(--cui-spacings-tera) var(--cui-spacings-tera) 0px var(--cui-spacings-tera);
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .headline {
    display: flex;
    padding: var(--cui-spacings-tera) 0 0;
    align-items: flex-start;
    align-self: flex-start;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-tera) 0 var(--cui-spacings-tera);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .headline {
    display: flex;
    padding-top: var(--cui-spacings-tera);
    align-items: flex-end;
    align-self: stretch;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    display: flex;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-tera) 0 var(--cui-spacings-tera);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
  }

  .headline {
    display: flex;
    padding: 0;
    align-items: flex-end;
    align-self: stretch;
  }
}

