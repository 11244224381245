.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.title {
  flex: 1 1 0;
  text-align: center;
  word-wrap: break-word;
}

.description {
  display: none;
  flex: 1 1 0;
  text-align: center;
  word-wrap: break-word;
}

.normal-text {
  font-weight: normal;
}

.bold-text {
  font-style: italic;
}

/* Mobile styles */
@media screen and (max-width: 480px) {
  .header {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
    flex-grow: 1;
  }

  .normal-text {
    font-weight: normal;
  }

  .bold-text {
    font-style: italic;
  }

  .searchContainer {
    display: flex;
    align-items: flex-start;
    padding: var(--cui-spacings-kilo) 0 0;
    align-self: stretch;
    color: var(--cui-fg-accent);
  }

  .searchContainer>* {
    flex-grow: 1;
  }
  
  .searchContainer>*>*>svg {
    color: var(--cui-fg-accent) !important;
  }
  
  .searchInput {
    border-radius: 40px;
    background: var(--cui-bg-highlight);
    box-shadow: none;
    font-weight: var(--cui-font-weight-bold);
    padding-left: var(--cui-spacings-peta);
    cursor: pointer;
  }
  
  .searchInput::placeholder {
    color: var(--cui-fg-accent);
  }

  .title {
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    display: none;
    text-align: center;
    word-wrap: break-word;
    margin-top: 16px;
  }
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .header {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
  }

  .normal-text {
    font-weight: normal;
  }

  .bold-text {
    font-style: italic;
  }

  .searchContainer {
    display: none;
  }

  .title {
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    display: none;
    text-align: center;
    word-wrap: break-word;
    margin-top: 16px;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    max-width: 768px;
  }

  .searchContainer {
    display: none;
  }

  .title {
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    display: flex;
    text-align: left;
    word-wrap: break-word;
    margin-top: 16px;
  }

  .normal-text {
    font-weight: normal;
  }

  .bold-text {
    font-style: italic;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1780px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    max-width: 768px;
  }

  .title {
    text-align: center;
    word-wrap: break-word;
  }

  .searchContainer {
    display: none;
  }

  .description {
    display: flex;
    text-align: left;
    word-wrap: break-word;
    margin-top: 16px;
  }

  .normal-text {
    font-weight: normal;
  }

  .bold-text {
    font-style: italic;
  }
}

@media screen and (min-width: 1780px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    max-width: 768px;
  }

  .searchContainer {
    display: none;
  }

  .title {
    text-align: center;
    word-wrap: break-word;
  }

  .description {
    display: flex;
    text-align: left;
    word-wrap: break-word;
    margin-top: 16px;
  }

  .normal-text {
    font-weight: normal;
  }

  .bold-text {
    font-style: italic;
  }
}